<template>
  <div
    v-if="recipe !== null"
    class="recipe-detail"
    itemscope
    itemtype="”http://schema.org/Recipe”">
    <router-link
      :to="{name: 'Recipes'}"
      class="close">
      <font-awesome-icon icon="times"/>
    </router-link>

    <article>
      <div class="article-header">
        <img :src="image"/>
        <h1>{{ recipe.attributes.title }}</h1>
      </div>
      <div class="recipe-detail-grid">
        <div class="content">
          <div
            v-if="recipe.attributes.body !== null"
            class="body"
            v-html="recipe.attributes.body.value"/>

          <h2>Ingrediënten</h2>
          <div
            v-if="recipe.attributes.field_ingredients_description !== null"
            class="ingredrients"
            v-html="recipe.attributes.field_ingredients_description.processed"/>

          <h2>Voorbereiding</h2>
          <div
            v-if="recipe.attributes.field_preparation !== null"
            class="preparation"
            v-html="recipe.attributes.field_preparation.processed"/>

          <h2>Bereiding</h2>
          <div
            v-if="recipe.attributes.field_instructions !== null"
            class="instructions"
            v-html="recipe.attributes.field_instructions.processed"/>
        </div>
        <div class="sidebar-right">
          <ul>
            <li>
              <font-awesome-icon
                class="icon"
                icon="clock"/>
              <span>Bereidingstijd: {{ recipe.attributes.field_cooking_time }}</span>
            </li>
            <li>
              <font-awesome-icon
                class="icon"
                icon="globe-africa"/>
              Keuken: {{ kitchens.join(', ') }}
            </li>
            <li>
              <font-awesome-icon
                class="icon"
                icon="utensils"/>
              Maaltijd: {{ meals.join(', ') }}
            </li>
            <li>
              <font-awesome-icon
                class="icon"
                icon="coins"/>
              Prijs per persoon: €{{ recipe.attributes.field_cost_price }},-
            </li>
            <li>
              <font-awesome-icon
                class="icon"
                icon="users"/>
              Aantal personen: {{ recipe.attributes.field_portions }}
            </li>
            <li>
              <font-awesome-icon
                class="icon"
                icon="tag"/>
              <span>Catergorie: {{ categories.join(', ') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import striptags from 'striptags';

export default {
  name: 'RecipeDetail',

  metaInfo () {
    return {
      title: this.title,
      meta : [
        { name: 'description', content: striptags(this.description) },
      ],
    };
  },

  data () {
    return {};
  },

  computed: {
    ...mapGetters([
      'getRecipeImageObject',
      'recipeData',
      'recipesMap',
      'selectedRecipe',
    ]),

    image () {
      return this.$store.getters.getRecipeImageUrl(this.recipe, 'recipe_header');
    },

    categories () {
      return this.fetchFieldValues(this.recipe.relationships.field_category.data);
    },

    kitchens () {
      return this.fetchFieldValues(this.recipe.relationships.field_kitchen.data);
    },

    meals () {
      return this.fetchFieldValues([this.recipe.relationships.field_meal.data]);
    },

    recipe () {
      if ('undefined' === typeof this.recipesMap) {
        return null;
      }

      if (null === this.selectedRecipe) {
        const recipe = this.recipesMap.get(this.$router.currentRoute.path);
        this.selectRecipe(recipe);
      }

      return this.selectedRecipe;
    },

    description () {
      return this.recipe && this.recipe.attributes ? this.recipe.attributes.body.value : '';
    },

    title () {
      return this.recipe && this.recipe.attributes ? this.recipe.attributes.title : '';
    },
  },

  created () {
    this.searchRecipes();
  },

  methods: {
    ...mapActions([
      'searchRecipes',
      'selectRecipe',
    ]),

    fetchFieldValues (data) {
      return data.map((item) => {
        const field = this.recipeData.included.find(term => item.id === term.id);
        return field ? field.attributes.name : null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

img {
    border-bottom: 2px solid $primary-color;
    max-width: 100%;
}

.recipe-detail {
    font-family: "Roboto";
    font-size: 18px;
    text-align: left;

    article {
        .article-header {
            position: relative;
            h1 {
                background-color: $primary-color;
                color: #ffffff;
                font-family: 'Slabo 27px', sans-serif;
                font-size: 28px;
                margin: 0;
                padding: 20px;
                position: relative;

                @media (min-width: 740px) {
                    bottom: 20px;
                    font-size: 40px;
                    margin: 20px 0;
                    position: absolute;
                }
            }
        }

        h2 {
            margin-bottom: 0;
        }
    }

    .recipe-detail-grid {
        padding: 20px;

        @media (min-width: 740px) {
            display: grid;
            grid-gap: 20px;
            grid-template-areas: "main main sidebar";
        }

        .content {
            grid-area: main;
            margin-bottom: 20px;
        }

        .sidebar-right {
            grid-area: sidebar;

            ul {
                background-color: $primary-color;
                color: white;
                list-style: none;
                padding: 20px;

                li {
                    display: flex;
                    flex-direction: row;
                    margin: 20px 0;

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .icon {
                        margin-right: 10px;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }
    }
}

.close {
    color: #ffffff;
    background-color: $primary-color;
    font-size: 48px;
    padding: 5px 20px;
    position: absolute;
    right: 0;
    z-index: 1;
}
</style>
